import React from "react"
import css from "@emotion/css"
import GatsbyImage from "gatsby-image"
import { PrimaryLink } from "./PrimaryButton"

const StandardImageCSS = css`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    margin-top: 0rem;
    width: 380px;
    margin-left: -20px;
  }
  @media (min-width: 992px) {
    width: 500px;

    margin-left: -20px;
  }
  @media (min-width: 1200px) {
    width: 600px;
    margin-left: -50px;
  }
`
const LeftImageCSS = css`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: -1rem;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    margin-top: 0rem;
    width: 440px;
    margin-left: -90px;
  }
  @media (min-width: 992px) {
    width: 580px;
    margin-left: -120px;
  }
  @media (min-width: 1200px) {
    width: 690px;
    margin-left: -130px;
  }
`

const TopImageCSS = css`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-top: -85px;
    width: 390px;
    margin-left: -60px;
  }
  @media (min-width: 992px) {
    width: 500px;
    margin-left: -30px;
    margin-top: -110px;
  }
  @media (min-width: 1200px) {
    width: 600px;
    margin-left: -45px;
    margin-top: -130px;
  }
`

export const HeroBanner = ({
  title,
  subtitle,
  buttonLink,
  buttonText,
  heroImage,
  version,
}) => (
  <div
    className="row"
    css={
      version === "top"
        ? css`
            @media (min-width: 768px) {
              padding-top: calc(2rem * 1.25);
            }
            @media (min-width: 992px) {
              padding-top: calc(2rem * 1.5);
            }
            @media (min-width: 1200px) {
              padding-top: calc(2rem * 2);
            }
          `
        : css``
    }
  >
    <div className="col-12 col-md-6 order-2 order-md-0">
      <div
        className="circle"
        css={css`
          background: #fff;

          h2 {
            font-size: 1.5em;
          }
          p {
            font-size: 1em;
          }

          padding-top: 2rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          border-radius: 50%;
          height: calc(100vw - 30px);
          margin-top: -2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (min-width: 576px) {
            padding-top: 0rem;
            padding-bottom: 0rem;
            margin-top: -5rem;
            width: 540px;
            height: 540px;

            h2 {
              font-size: 2.5rem;
            }
            p {
              font-size: 1.5rem;
            }
          }
          @media (min-width: 768px) {
            padding-top: 0rem;
            padding-bottom: 0rem;
            margin-top: 1rem;
            width: 380px;
            height: 380px;
            border-radius: 50%;
          }
          @media (min-width: 992px) {
            width: 500px;
            height: 500px;
          }
          @media (min-width: 1200px) {
            width: 600px;
            height: 600px;
          }
        `}
      >
        <div
          css={css`
            padding: 1rem;
            text-align: center;
            @media (min-width: 768px) {
              padding: 0;
              max-width: 300px;
            }
            @media (min-width: 992px) {
              text-align: left;
            }
          `}
        >
          {" "}
          {title && (
            <h2
              className="bold-headline"
              css={css`
                font-size: 2rem;
                @media (min-width: 576px) {
                }
                @media (min-width: 768px) {
                }
                @media (min-width: 992px) {
                  font-size: 2.3rem;
                }
                @media (min-width: 1200px) {
                }
              `}
            >
              {title}
            </h2>
          )}
          {subtitle && <p className="lead">{subtitle}</p>}{" "}
          {buttonText && buttonLink && (
            <PrimaryLink to={buttonLink} text={buttonText} />
          )}
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 ">
      {heroImage && (
        <GatsbyImage
          fadeIn={true}
          placeholderStyle={{ opacity: 0 }}
          css={
            version === "top"
              ? TopImageCSS
              : version === "left"
              ? LeftImageCSS
              : StandardImageCSS
          }
          fluid={heroImage.fluid}
        />
      )}
    </div>
  </div>
)

import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/layouts/header"
import css from "@emotion/css"
import { graphql } from "gatsby"
import groupBy from "lodash/groupBy"
import { PrimaryLink } from "../components/PrimaryButton"
import { HeroBanner } from "../components/HeroBanner"
function Index({ data }) {
  const {
    contentfulCareersPage: {
      pageTitle,
      metaDescription,
      heroBanner: { title, heroImage },
      subBanner,
      roles,
    },
  } = data

  const categories = groupBy(roles, "category.title")

  return (
    <>
    <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content="Ratio, fintech, finance, software, careers at ratio, careers, jobs, roles, job roles, job search, job listing, search jobs, career, employment, work, find jobs, engineering, developers, analysts" />
    </Helmet>
      <div>
        <div className="hero bg-gradient">
          {" "}
          <Header />
          <div id="heroBanner" className="container section-py">
            <HeroBanner version="left" heroImage={heroImage} title={title} />
          </div>
        </div>
      </div>
      <TextSection
        aCSS={`
              padding-top: 60px;
              padding-bottom: 60px;

        `}
        html={subBanner.md.html.replace(":", ": <br/>")}
      />
      <div
        className="section-py"
        css={css`
          background-color: #e7eaea;
        `}
      >
        <div className="container">
          {Object.keys(categories).map(key => {
            return (
              <React.Fragment key={key}>
                <div className="row">
                  <div className="col-12 col-md-12">
                    {" "}
                    <h2
                      className="text-primary"
                      css={css`
                        font-family: "futura-pt", sans-serif;
                        margin-bottom: 50px;
                        margin-top: 50px;
                        font-size: 36px;
                        font-weight: 600;
                      `}
                    >
                      {key}
                    </h2>
                  </div>
                  {categories[key].map(role => (
                    <React.Fragment key={role.title}>
                      <div
                        className="col-md-6 col-lg-4 col-12"
                        css={css`
                          padding: 0.5rem;
                          margin-bottom: 40px;
                        `}
                      >
                        <div
                          className="bg-white"
                          css={css`
                            padding: 25px;

                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            & > * {
                              margin-bottom: 1rem;
                            }
                            a {
                              font-size: 1rem;
                            }
                            @media (min-width: 768px) {
                              min-height: 350px;
                              a {
                                font-size: 1.25rem;
                              }
                            }
                          `}
                        >
                          {" "}
                          <h3
                            css={css`
                              font-family: "futura-pt", sans-serif;
                              font-size: 28px;
                              font-weight: 600;
                            `}
                          >
                            {role.title}
                          </h3>
                          <div>
                            Location: {role.location}
                            <br />
                            Salary: {role.salary}
                            <br />
                            Start date: {role.startDate}
                            <br />
                            Duration: {role.duration}
                          </div>
                          <div>
                            <PrimaryLink
                              text="Find out more"
                              to={`/careers/${role.slug}`}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Index

export const query = graphql`
  {
    contentfulCareersPage {
      pageTitle: title
      metaDescription
      heroBanner {
        title
        heroImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
      subBanner {
        md: childMarkdownRemark {
          html
        }
      }
      roles {
        title
        slug
        location
        duration
        salary
        startDate
        category {
          title
        }
      }
    }
  }
`

const TextSection = ({ html, aCSS }) => (
  <section>
    <div
      className="container "
      css={css`
        ${aCSS}
      `}
    >
      {" "}
      <div className="row">
        <div className="col-12 col-md-9 col-lg-6 offset-md-1">
          <div
            id="aboutContentTop"
            css={css`
              h1,
              h2 {
                font-family: "futura-pt", sans-serif;
                font-weight: 600;

                margin-bottom: 2rem;
                color: var(--primary);

                @media (min-width: 992px) {
                  max-width: 60%;
                }
              }
              h2 {
                font-size: 36px;
              }
              h3 {
                font-size: 28px;
              }
            `}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </div>
      </div>
    </div>
  </section>
)

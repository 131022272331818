import React from "react"
import css from "@emotion/css"
import { Link } from "gatsby"
const primaryCSS = css`
  padding: 0.8em 1.25em;
  font-size: 1rem;
  /* white-space: nowrap; */
  @media (min-width: 992px) {
    font-size: 1.25rem;
  }
`
export default function PrimaryButton({ text, className, ...rest }) {
  return (
    <button
      className={`btn btn-lg text-white bg-gradient ${className}`}
      {...rest}
      css={primaryCSS}
    >
      {text}
    </button>
  )
}

export const PrimaryLink = ({
  text,
  to,
  className,
  light,
  external,
  ...rest
}) => {
  return external ? (
    <a
      {...rest}
      className={`btn btn-lg ${
        light ? "text-primary bg-white" : "text-white bg-gradient"
      } ${className}`}
      css={primaryCSS}
      href={to}
    >
      {text}
    </a>
  ) : (
    <Link
      {...rest}
      className={`btn btn-lg ${
        light ? "text-primary bg-white" : "text-white bg-gradient"
      } ${className}`}
      css={primaryCSS}
      to={to}
    >
      {text}
    </Link>
  )
}
